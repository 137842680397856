import * as React from "react";
import * as Gatsby from "gatsby";
import Layout from "../../components/Layout";
import {
    Avatar, Box,
    Button,
    Container, Divider, Flex,
    Heading,
    Link, ListItem, OrderedList,
    Tooltip, Text, useDisclosure
} from "@chakra-ui/react";
import {Helmet} from "react-helmet";
import VersionModal from "../../components/nav/VersionModal";

/*
程序员实用导航
1. 电子书
2. 开发者
3. 设计师
 */

/**
 * NavForProgrammers 对应导航
 * @returns {JSX.Element}
 * @constructor
 */
const NavForProgrammers = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Layout>
            <Container minW={'80vw'}>
                <Container my={4}>
                    <Helmet title={`${PAGE_TITLE} | Yuhan Tech`} defer={false} />
                    <Heading as={'h1'} size='2xl' textAlign={'center'} id={'title'}>
                        {PAGE_TITLE}
                        <Text as={'span'} fontSize={12} ml={2} _hover={{cursor: 'pointer'}} onClick={onOpen}>v{UPDATE_TIME}</Text>
                    </Heading>
                </Container>
                <Container textAlign={'center'} my={4}>
                    {
                        PAGE_BUTTON.map((item) => (
                            <Tooltip hasArrow label={item.remark} bg='gray.300' color='black' offset={[0, 20]} key={item.to}>
                                <Gatsby.Link to={item.to}>
                                    <Button m={4}>{item.label}</Button>
                                </Gatsby.Link>
                            </Tooltip>
                        ))
                    }
                </Container>
                <Container mx={0} my={4} >
                    {
                        PAGE_NAV.map((item) => (
                            <Container mx={0} my={4} key={item.id}>
                                <Heading id={item.id} > {item.label} </Heading>
                                <Divider w={'80vw'}/>
                                <OrderedList>
                                    {item.lists && item.lists.map((list, index) => <ListItem ml={4} mt={2} key={index}>{list}</ListItem>)}
                                </OrderedList>
                                <Flex wrap={'wrap'} my={4} w={'80vw'} gap='2' justifyContent={'flex-start'}>
                                    {item.children.map((child) => (
                                        <Link display="flex" href={child.href ?? 'javascript:void(0);'} isExternal={!!child.href} key={child.label} _hover={{textDecoration: 'none'}}>
                                            <Tooltip hasArrow label={child.remark ?? ''} bg='white' color='black' offset={[0, 10]}>
                                                <Box display="flex" alignItems='center' justifyContent='flex-start' width={300} boxShadow={'xs'} _hover={{boxShadow: 'lg'}} rounded={'lg'} p={4} m={2} textAlign={'center'}>
                                                    <Avatar src={child.img}/>
                                                    <Heading size={'md'} ml={'2'}> {child.label} </Heading>
                                                </Box>
                                            </Tooltip>
                                        </Link>
                                    ))}
                                </Flex>
                            </Container>
                        ))
                    }
                </Container>
            </Container>
            <VersionModal isOpen={isOpen} onClose={onClose} milestones={VERSION_HISTORY} />
        </Layout>
    )
}

const PAGE_TITLE = "Programmers' Navigation"

const UPDATE_TIME = '2022.6.2'

const VERSION_HISTORY = [
    {
        title: 'v2022.5.31',
        description: `程序员导航的初始版本`,
    },
    {
        title: 'v2022.6.1',
        description: '删除码农书籍网（下载后解压密码收费还很贵，这种网站应该灭绝），增加 Piping-Server'
    },
    {
        title: 'v2022.6.2',
        description: '增加 Piping Server'
    }
]

VERSION_HISTORY.reverse()  // 倒序

const PAGE_BUTTON = [
    {
        label: '电子书',
        to: '#ebooks'
    },
    {
        label: '开发者',
        to: '#developer'
    },
    {
        label: '设计师',
        to: '#designer'
    }
]

/*
注意：
1. 如果没有 href 则什么也不做。
 */
const PAGE_NAV = [
    {
        id: 'ebooks',
        label: '电子书',
        children: [
            {
                label: '天浪书屋',
                img: 'http://www.tianlangbooks.com/wp-content/uploads/2020/09/tianlangbooks.com_2020-09-25_21-54-31.jpg',
                href: 'https://www.tianlangbooks.com/'
            }
        ],
    },
    {
        id: 'developer',
        label: '开发者',
        children: [
            {
                label: 'gitmoji',
                img: 'https://cdn.jsdelivr.net/gh/dfface/img0@master/2022/05-31-giurCD-FokHGb.jpg',
                href: 'https://gitmoji.dev/',
                remark: 'An emoji guide for your commit messages'
            },
            {
                label: 'Piping Server',
                img: 'https://iph.href.lu/400x400?text=PS',
                href: 'https://ppng.io',
                remark: '在任何设备之间通过HTTP/HTTPS进行无限传输'
            }
        ],
    },
    {
        id: 'designer',
        label: '设计师',
        children: [
            {
                label: 'SVG REPO',
                img: 'https://cdn.jsdelivr.net/gh/dfface/img0@master/2022/05-31-LEdhdA-vgBznD.jpg',
                href: 'https://www.svgrepo.com/',
                remark: 'Search and find open source SVG graphics fastest way'
            },
            {
                label: 'WORLD VECTOR LOGO',
                img: 'https://worldvectorlogo.com/static/img/favicon.ico',
                href: 'https://worldvectorlogo.com/',
                remark: 'DOWNLOAD VECTOR LOGOS of brands you love'
            },
        ],
    },

]

export default NavForProgrammers;