import * as React from 'react';
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import Milestones from "./Milestones";

export default function (props) {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} scrollBehavior={'inside'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Version History</ModalHeader>
                <ModalBody>
                    <Milestones milestones={props.milestones}/>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}